<template>
  <div style="height: 100%;">
    <div style="display: flex; align-items: center;" class="Ge">
      <van-icon size="20" name="arrow-left" @click="$router.back()" />
      <van-search
        v-model="keyWord"
        shape="round"
        @input="input"
      >
      </van-search>
      <van-button
        v-if="showHide"
        color="linear-gradient(to right, #ff6034, #ee0a24)" 
        size="mini" 
        round
        @click="search"
        @click-input="focus"
        type="primary"
      >搜索</van-button>
    </div>

    <div class="container">
      <div 
        style="display: flex; padding: 10px 0;
        border-bottom: 1px solid rgba(223, 223, 223, .4);"
        v-for="(item, index) in shops"
        :key="index"
        class="clickBackground"
        @click="clickShop(item.goodsId)"
      >
        <img 
          :src="item.goodsImages[0]" alt=""
        >
        <div style="flex: 1;">
          <div>{{ item.goodsName }}</div>
          <div style="margin: 3px 0 5px;">
                  <van-tag
                    style="margin-right: 5px;"
                    color="#D8D8D8" 
                    v-for="(item2, index2) in item.standardName" 
                    :key="index2"
                    text-color="#333"
                  >
                    {{ item2.standardContent }}
                  </van-tag>
                </div>
          <div style="width: 63px; color: #333; font-size: 12px; transform: scale(.8) translateX(-8px);">月售{{ item.goodsMouthSell }}份</div>
          <div style="display: flex;" v-if="item.noSingle ==1 ? true : false">
            <div style="font-size: 12px; transform: scale(.8) translateX(-8px); border: 1px solid #333; padding: 1px 10px; border-radius: 5px;">
              {{ item.noSingle == 1 ? '单点不送' : '' }}
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: flex-end;">
            <div>
              <span style="color: red;">
                <span style="font-size: 12px;">￥</span>
                <span>
                  {{ 
                    item.discountPrice ? (item.discountUser == 0 ? item.discountPrice: (flag == 0 ? item.discountPrice : item.price)) : item.price
                  }}
                </span>
              </span>
              <span 
                v-if="item.discountPrice ? item.discountUser == 0 ? true : flag == 0 ? true : false : false"
                style="color: red; color: #999; text-decoration: line-through; margin-left: 5px;"
              >
                <span
                  style="font-size: 12px; font-size: 12px;"
                >￥</span>{{ item.price }}

              </span>
            </div>

            <div style="display: flex; flex-direction: column; background: rgb(246, 245, 240);">
              <div
                  v-if="item.minBuy == 1 || item.minBuy == null || item.minBuy == '' ? false : true"
                  style="foot-size: 12px; 
                  display: flex; 
                  justify-content: center; 
                  align-items: center;
                  color: rgb(140, 140, 140);
                  transform: scale(.75);"
                >
                  <span style="">{{ item.minBuy }}份起购</span>
                </div>
              <van-button
                size="mini" 
                color="#FF5200" 
                @click.stop="selectSpecification(item.goodsName, item.goodsId, 
                  item.goodsImages[0], item.discountPrice, 
                  item.price, item.discountUser,
                  item.stockCurrent,item.noSingle)"
              >
                选规格
              </van-button>
          </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 结算 -->
    <div class="bottom-GoodsAction">
      <van-goods-action>
        <van-goods-action-icon icon="cart-o" text="购物车" @click="clickCart" />
        <div 
          style="border-radius: 20px 0 0 20px;
          width: 60%;
          position: relative;
          box-sizing: border-box;        
          background: #313131; 
          height: 40px;"
        >
          <div
            style="display: flex;
            top: 3.5px;
            left: 8.2%;
            position: absolute;
            align-items: center;"
          >
            <div style="color: #fff;">
              <span style="font-size: 12px;">￥</span>
              <span style="font-size: 14px;">{{ shopPrice[1] }}</span>
            </div>
            <div style="font-size: 12px; transform: scale(.83); margin-top: 3px; color: #999;">￥{{ shopPrice[0] }}</div>
          </div>
          <div
            style="font-size: 12px;
            position: absolute;
            bottom: 0px;
            left: 15px;
            color: #fff;"
          >
            <span>预估配送费￥{{ transportUserPrice }}</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
              v-if="transportStartPriceResult > 0 ? true : false"
            >差￥{{ transportStartPriceResult }}起送</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
            >
              {{
                transportStartPriceResult > 0 ? '' : noDistribution == true ? '单点不配送' : ''
              }}
            </span>
          </div>
        </div>
        <van-goods-action-button
          :disabled="disabled"
          type="danger"
          text="去结算"
          @click="goSettle"
        />
      </van-goods-action>
    </div>

    <!-- 购物车面板 -->
    <div>
      <van-action-sheet v-model="show">
        <div 
          style="padding: 53px 4% 51px; position: relative;"
          class="shop-box"
        >
          <div
            style="position: absolute;
              top: 0;
              left: 3.9%;
              right: 4%;
              width: 92%;
              padding: 15px 0;
              background: #fff;"
            >
            <p 
              style="font-size: 15px;
              color: #333; text-align: center;"
            >购物车</p>
            <div
              style="color: #999; 
              position: absolute; 
              right: 0;
              display: flex;
              align-items: center;
              top: 15px;"
              @click="emptyShopCar"
            >
              <van-icon name="delete-o" size="20"/>
              <span style="font-size: 12px; margin-left: 3px; padding-top: 2px;">清空购物车</span>
            </div>
          </div>

          <div>
            <!-- 购物车商品 -->
            <div class="shopCartShop">
              <div
                style="display: flex; 
                  justify-content: space-between; 
                  padding: 10px 0;"
                  v-for="(item, index) in shopCar"
                  :key="item.nanoid"
              >
                <img style="width: 93px; height: 93px;" :src="item.goodsImages" alt="">
                <div style=" width: 69%;">
                  <p style="font-size: 13px; color: #333;">{{ item.goodsName }}</p>
                  <p style="font-size: 12px; color: #999; margin-top: 5px;">
                    <span
                      style="margin-right: 5px;"
                      v-for="(item2, index2) in item.specificationShow"
                      :key="index2"
                    >{{ item2 }}</span>
                  </p>
                  <div 
                    style="display: flex; 
                      justify-content: space-between;
                      margin-top: 10px;
                      align-items: center;">
                    <div style="display: flex;">
                      <div style="display: flex; margin-right: 5px; color: #A80000;">
                        <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥</div><span>{{ (item.price * item.quantity).toFixed(2) }}</span>
                      </div>
                      <div style="display: flex; text-decoration: line-through; color: #999;">
                        <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥{{ (item.originalPrice * item.quantity).toFixed(2) }}</div>
                      </div>
                    </div>
                    <div>
                      <van-stepper 
                        v-model="item.quantity" 
                        disable-input
                        min="0"
                        button-size="19"
                        input-width="19"
                        @change="shopCarChange(index,$event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>


    <!-- 选规格弹框 -->
    <div class="shopSpecification">
      <van-dialog 
        v-model="dialogShow"
        width="89%"
        :close-on-click-overlay="true"
        :showConfirmButton="false"
      >
        <div style="padding: 20px 5.3%; 0">
          <p style="text-align: center; color: #333;">{{ goodsName }}</p>
          <div>
            <div 
              v-for="(item, index) in standard" :key="index"
              style="margin-bottom: 3px;"
            >
              <p style="font-size: 12px; color: #333; margin: 6px 0 3px;">{{ item.name }}</p>
              <div 
                style="display: flex;"
              >
                <div
                  v-for="(item2, index2) in item.contenList"
                  :key="index2"
                  :class="{copies: copies[index] == index2}"
                  @click="cppiesTabs(index, index2, item2.standardName, item2.addPrice, item2.standardId, item2.id)"
                  style="width: 85px; 
                  height: 30px; font-size: 12px;
                  border: 1px solid #999;
                  display: flex;
                  margin: 0 4% 0 0;
                  align-items: center;
                  justify-content: center;
                  border-radius: 5px;"
                >{{ item2.standardName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="font-size: 12px; #999;
            display: flex;
            padding-left: 5.3%;
            margin-top: 20px;
            box-sizing: border-box;
            align-items: center;
            background: #F6F6F6; 
            height: 33px;"
        >
          <span style="color: #999;">
            <span>已选规格：</span>
            <span style="color: #333;" v-for="(item, index) in specificationShow" :key="index">
              {{ item }} {{ specificationShow.length == index + 1 ? '' : '、' }}
            </span>
          </span>
        </div>

        <div class="totalAmount">
          <div style="display: flex; align-items: center;">
            <span style="font-size: 14px; color: #333;">总计</span>&nbsp;
            <div style="font-size: 12px; margin-top: 5px; font-weight: 600;">￥</div>
            <span style="font-size: 24px; color: #333; font-weight: 600; margin-bottom: 3px;">{{ totalPrice.toFixed(2) }}</span>
          </div>

          <van-button
            v-if="shopExist ? false : true"
            icon="plus"
            type="primary"
            size="mini"
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="add_cart_shop"
          >加入购物车</van-button>

          <div v-if="shopExist ? true : false">
            <van-stepper 
              v-model="guigeStepper"
              disable-input
              min="0"
              button-size="19"
              input-width="19"
              @change="stepperChange"
            />
          </div>
        </div>

        <div 
          style="
            width: 39px; height: 39px;
            border: 1px solid #fff;
            background: rgba(147, 147, 147, .4);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            bottom: -65px;
            transform: translateX(-50%);
          "
          @click="dialogShow = false"
        >
          <van-icon 
            size="30" 
            name="cross"
            color="#fff"
          />
        </div>
      </van-dialog>
    </div>

  </div>
</template>

<script>
import { searchShops, selectStandards, newuserflag } from '@/api/Ge'
import { mapState, mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import qs from 'qs'
export default {
  data () {
    return {
      keyWord: '',
      showHide: false,
      show: false,
      stepper: '',
      shops: [],
      dialogShow: false,
      cartShow: true,
      stepperShow: false,
      discountPrice: null, // 商品折扣价

      copies: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 份数
      goodsName: '', // 规格商品名称
      goodsId: '', // 规格框 判断购物车 步进器
      goodsImages: '', // 商品列表图片

      standard: [], // 规格
      specificationShow: [], // 已选规格
      shopExist: false, // 判断商品是否存在
      priceArr: [], // 价格数组
      specificationArr: [], // 规格数组
      noSingle: null, // 单点不送

      basicPrice: 0, // 商品列表基础价格
      originalPrice: 0, // 商品原价
      originalPrice2: 0, // 商品原价
      totalPrice: 0, // 总价
      guigeStepper: 0, // 规格步进器值
      flag: '', // 0-新用户 1 老用户 
        // flag: 0, // 0-新用户 1 老用户
    }
  },
  computed: {
      ...mapState({
        shopCar: state => JSON.parse(JSON.stringify(state.shopCar.shopCar)),
        transportUserPrice: state => state.shopCar.transportUserPrice,
        transportStartPrice: state => state.shopCar.transportStartPrice,
        disabled: state => state.shopCar.disabled,
      }),
      ids () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/goodsIds']))
      },
      shopPrice () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/shopPrice']))
      },
      transportStartPriceResult () { // 起送价结果
        let transportStartPriceResult = parseFloat(this.transportStartPrice) - parseFloat(this.shopPrice[1])
        return transportStartPriceResult.toFixed(2)
      },
      noDistribution () {
        return this.$store.getters['shopCar/noDistribution']
      }
  },
  watch: {
    dialogShow (value) {
      if (!value) {
        this.priceArr = []
        this.specificationArr = []
        this.basicPrice = 0
        this.specificationShow = []
        this.discountPrice = null
        this.noSingle = null
      } else {
        this.copies = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    },

    transportStartPriceResult (val) {
      if (val <=0) {
        const noSingleShop = JSON.parse(JSON.stringify(this.shopCar))
        const noSingleShopTrue = noSingleShop.every(item => item.noSingle == '1')
        if (noSingleShopTrue) {
          this['shopCar/set_disabled'](true)
        } else {
          this['shopCar/set_disabled'](false)
        }
      } else {
        this['shopCar/set_disabled'](true)
      }
    }
  },
  created () {
    this.keyWord = this.$route.query.name
    this.newuserflag()
    this.searchShops()
  },
  methods : {
    ...mapMutations([
      'shopCar/change_quantity', 
      'shopCar/add_distance', 
      'shopCar/delete_shop',
      'shopCar/set_shopCar',
      'shopCar/transport_User_Price',
      'shopCar/lot_lat',
      'shopCar/empty_shop_car',
      'shopCar/set_disabled'
    ]),

    // 新用户
    newuserflag () {
      newuserflag(qs.stringify({
        shopId: this.$route.query.shopId
      })).then(res => {
        if (res.data.code === 0) {
          this.flag = res.data.data.flag
        }
      })
    },

    input (value) {
      if (value) {
        this.searchShops()
      } else {
        this.$router.replace({
          path:'/search',
          query: {
            shopId: this.$route.query.shopId
          }
        })
      }
      // this.showHide = true
    },

    searchShops () {
      searchShops(qs.stringify({
        shopId: this.$route.query.shopId,
        name: this.keyWord
      })).then(res => {
        if (res.data.code === 0) {
          this.shops = res.data.data.records
        }
      })
    },

    focus () {
      alert(3)
    },

    search () {
      this.searchShops()
      // this.showHide = false
    },

    // 购物车
    clickCart () {
      if (this.show == false) {
        this.show = true
      } else {
        this.show = false
      }
    },

    // 跳商品详情
    clickShop (id) {
      this.$router.push({
        path: '/shoppingDetail',
        query: {
          shopId: this.$route.query.shopId,
          id
        }
      })
    },

    // 选规格
    selectSpecification (goodsName, goodsId, goodsImages, basicPrice, originalPrice,discountUser,stockCurrent,noSingle) {
      if (stockCurrent == 0) {
        this.$toast('商品已售空')
        return
      }
      this.dialogShow = true
      this.noSingle = noSingle
      this.discountPrice = basicPrice
      this.goodsName = goodsName
      this.goodsId = goodsId
      this.goodsImages = goodsImages
      if (basicPrice) {
        // 所有用户折扣价
        if (discountUser == '0') {
          this.basicPrice = basicPrice
        } else if (discountUser == '1') { // 折扣价只针对新用户
          if (this.flag == '0') {
            this.basicPrice = basicPrice
          } else if (this.flag == '1') {
            this.basicPrice = originalPrice
          }
        }
      } else {
        this.basicPrice = originalPrice
      }
      this.originalPrice2 = originalPrice
      selectStandards(qs.stringify({
        shopId: this.$route.query.shopId,
        goodsId
      })).then(res => {
        if (res.data.code === 0) {
          this.standard = res.data.data
          let price = 0
          res.data.data.forEach((item, index) => {
            item.contenList.forEach((item2, index2) => {
              if (index2 === 0) {
                this.specificationShow[index] = item2.standardName
                price = price + item2.addPrice
                this.priceArr.push(item2.addPrice)
                let params = {
                  standardId: item2.standardId,
                  id: item2.id
                }
                this.specificationArr.push(params)
              }
            })
          })
          this.totalPrice = price + this.basicPrice
          this.originalPrice = price + originalPrice // 规格弹框关闭为侦听
          this.judgeShop(this.goodsId, this.specificationArr)
          this.stepperNum(goodsId, this.specificationArr)
        }
      })
    },

    // 规格
    cppiesTabs (index, index2, name, addPrice, standardId, id) {
      this.copies[index] = index2
      this.copies = this.copies.concat([])
      this.specificationShow[index] = name
      this.priceArr[index] = addPrice 
      let price = 0
      this.priceArr.forEach(item => {
        price = price + item
      })
      this.totalPrice = price + this.basicPrice
      this.originalPrice = price + this.originalPrice2
      let params = {
        standardId,
        id
      }
      this.specificationArr[index] = params
      this.specificationArr = this.specificationArr.concat([])
      this.judgeShop(this.goodsId, this.specificationArr)
      this.stepperNum(this.goodsId, this.specificationArr)
    },

    judgeShop (goodsId, specificationArr) {
      const specificationArrId = []
      specificationArr.forEach(item2 => {
        specificationArrId.push(item2.id)
      })
      const ids = JSON.parse(JSON.stringify(this.ids))
      const shopCar  = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
      if (ids.includes(goodsId)) {
        const numBer = []
        shopCar.forEach(item => {
          const numArr2 = []
          item.allGoods.forEach(item2 => {
            numArr2.push(item2.id)
          })
          const trueFalse = specificationArrId.every( item => numArr2.includes(item))
          if (trueFalse) {
            numBer.push(1)
          } else {
            numBer.push(2)
          }
        })
        if (numBer.includes(1)) {
          this.shopExist = true
        } else {
          this.shopExist = false
        }
      } else {
        this.shopExist = false
      }
    },

    // 渲染规格弹框步进器
    stepperNum (goodsId, specificationArr) {
      const newSpecificationArr = []
      specificationArr.forEach(item => newSpecificationArr.push(item.id))
      const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
      shopCar.forEach(item => {
        const ids = []
        item.allGoods.forEach(item2 => {
          ids.push(item2.id)
        })
        const trueFalse = newSpecificationArr.every(item3 => ids.includes(item3))
        if (trueFalse) {
          this.guigeStepper = item.quantity
        }
      })
    },

    // 步进器
    stepperChange (num) {
      if (num == 0) {
        this['shopCar/delete_shop']({
          num,
          specificationArr: JSON.parse(JSON.stringify(this.specificationArr)),
          goodsId: this.goodsId
        })
      } else {
        this['shopCar/change_quantity']({
          goodsId: this.goodsId,
          specificationArr: this.specificationArr,
          guigeStepper: this.guigeStepper
        })
        this.stepperNum(this.goodsId, this.specificationArr)
      }
      this.judgeShop(this.goodsId, this.specificationArr)
    },

    // 面板步进器
    shopCarChange (index, e) {
      if (e === 0) {
        this.shopCar.splice(index, 1)
      }
      this['shopCar/set_shopCar'](this.shopCar)
    },

    emptyShopCar () {
      this['shopCar/empty_shop_car']()
      this.show = false
    },

    // 规格弹框 加入购物车
    add_cart_shop () {
      const Goodsnanoid = nanoid()
      let params = {
        nanoid: Goodsnanoid,
        goodsId: this.goodsId,
        goodsName: this.goodsName,
        goodsImages: this.goodsImages,
        price: this.totalPrice,
        originalPrice: this.originalPrice,
        // allGoods: '商品Id@规格Id:规格内容Id_规格Id:规格内容Id@1@商品名称',
        allGoods: this.specificationArr,
        specificationShow: this.specificationShow,
        discountPriceL: this.discountPrice,
        noSingle: this.noSingle,
        quantity: 1
      }
      params = JSON.parse(JSON.stringify(params))
      this.$store.commit("shopCar/add_shop", params)
      this.judgeShop(this.goodsId, this.specificationArr)
      this.stepperNum(this.goodsId, this.specificationArr)
      console.log(this.$store.state.shopCar.shopCar, '仓库')
    },

    // 去结算
    goSettle () {
      const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
      const shopCateId = []
      shopCar.forEach(item => shopCateId.push({ minBuy: item.minBuy, goodsId: item.goodsId, goodsName: item.goodsName }))
      const arr = shopCateId.map(item => JSON.stringify(item))
      const result = Array.from(new Set(arr)).map((item) => JSON.parse(item))
      let shopName = []
      result.forEach(item => {
        const minBuy = item.minBuy
        let quantity = 0
        shopCar.forEach(item2 => {
          if (item.goodsId == item2.goodsId) {
            quantity = item2.quantity + quantity
          }
        })
        if (minBuy > quantity) {
          shopName.push(item.goodsName)
        }
      })
      if (shopName.length) {
        let str = ''
        shopName.forEach((item, index) => {
          if (index != shopName.length-1) {
            str = str + item + ','
          } else {
            str = str + item
          }
        })
        this.$toast(str+ '商品起购数量不足')
      } else {
        this.$router.push({
          path: '/settle',
          query: {
            shopId: this.$route.query.shopId,
            shopName: this.$route.query.name,
          }
        })
      }
    }
  }
}

</script>

<style lang="less" scoped>
.Ge {
  position: fixed;
  width: 100%;
  padding: 0 2%;
  box-sizing: border-box;
  background: white;
  z-index: 999999;
  .van-search {
    flex: 1;
  }
  .van-button--mini {
    padding: 0 10px;
  }
}
.bottom-GoodsAction .van-goods-action-button--first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}
.bottom-GoodsAction .van-goods-action {
  z-index: 2100;
}
.bottom-GoodsAction  {
  .van-goods-action-icon {
    transform: scale(.83);
  }
  /deep/ .van-goods-action-icon__icon {
    // color: red;
    transform: scale(1.7);
  }
}
/deep/ .shopCartShop {
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
.container {
  padding: 54px 2% 50px;
  background: #fff;
  img {
    width: 82px;
    height: 82px;
    margin-right: 10px;
  }
  p {
    font-weight: bold;
  }
  .van-button--mini {
    height: 16px;
  }
  .clickBackground:active {
    background: #f2f3f5;
  }
}
/deep/ .shopSpecification {
  position: relative;
  .van-overlay {
    z-index: 2200 !important;
  }
  .van-dialog {
    z-index: 2300 !important;
    .van-dialog__header {
      padding-top: 20px;
    }
  }
  .van-dialog {
    overflow: visible !important;
    .van-button--mini {
      padding: 2px 12.5px;
    }
  }
}
.copies {
  border: 1px solid #FFE5C8 !important;
  background: #FFE5C8;
  color: #FF5200;
}
/deep/ .totalAmount {
  padding: 8px 5.3% 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
</style>